export const environment = {
   production: true,
   firebase: {
      apiKey: 'AIzaSyDDfOoHFmePUq-N3dWGg1Q7WVJmP9ze_5s',
      authDomain: 'tenis-turnuvasi-dev.firebaseapp.com',
      databaseURL: 'https://tenis-turnuvasi-dev-default-rtdb.firebaseio.com',
      projectId: 'tenis-turnuvasi-dev',
      storageBucket: 'tenis-turnuvasi-dev.appspot.com',
      messagingSenderId: '43943583152'
    },
    apiUrl: 'https://us-central1-tenis-turnuvasi-dev.cloudfunctions.net/api/'
};
