import { Component, OnInit } from '@angular/core';

import { UserService } from '@core/services/user.service';
import { ThemeService } from '@core/services/theme.service';
import { ThemeType } from '@shared/enums/theme-type.enum';

@Component({
  selector: 'app-layout-default',
  templateUrl: './default.component.html'
})
export class LayoutDefaultComponent implements OnInit {
  activeUser: Users.User;
  appearanceDrawer = false;
  theme;
  themeType = ThemeType;
  mainMenu = [
    {
      title: 'Dashboard',
      link: ['/', 'dashboard'],
      role: ['ROLE_ADMIN'],
      child: []
    },
    {
      title: 'Kulüpler',
      role: ['ROLE_ADMIN', 'ROLE_HEAD_COACH', 'ROLE_ASSISTANT_COACH'],
      child: [
        {
          title: 'Kulüpler',
          link: ['/', 'clubs', 'list'],
          role: ['ROLE_ADMIN', 'ROLE_HEAD_COACH', 'ROLE_ASSISTANT_COACH']
        }
      ]
    },
    {
      title: 'Kortlar',
      role: ['ROLE_ADMIN', 'ROLE_HEAD_COACH', 'ROLE_ASSISTANT_COACH'],
      child: [
        {
          title: 'Kortlar',
          link: ['/', 'courts', 'list'],
          role: ['ROLE_ADMIN', 'ROLE_HEAD_COACH', 'ROLE_ASSISTANT_COACH']
        },
        {
          title: 'Kort Müsaitlik',
          link: ['/', 'courts', 'availability'],
          role: ['ROLE_ADMIN', 'ROLE_HEAD_COACH', 'ROLE_ASSISTANT_COACH']
        }
      ]
    },
    {
      title: 'Ligler',
      role: ['ROLE_ADMIN', 'ROLE_HEAD_COACH', 'ROLE_ASSISTANT_COACH'],
      child: [
        {
          title: 'Ligler',
          link: ['/', 'leagues', 'list'],
          role: ['ROLE_ADMIN', 'ROLE_HEAD_COACH', 'ROLE_ASSISTANT_COACH']
        },
        {
          title: 'Oyuncular',
          link: ['/', 'leagues', 'players'],
          role: ['ROLE_ADMIN', 'ROLE_HEAD_COACH', 'ROLE_ASSISTANT_COACH']
        },
        {
          title: 'Lig Başvuruları',
          link: ['/', 'leagues', 'applications'],
          role: ['ROLE_ADMIN', 'ROLE_HEAD_COACH', 'ROLE_ASSISTANT_COACH']
        }
      ]
    },
    {
      title: 'Turnuvalar',
      role: ['ROLE_ADMIN', 'ROLE_HEAD_COACH', 'ROLE_ASSISTANT_COACH'],
      child: [
        {
          title: 'Turnuvalar',
          link: ['/', 'tournaments', 'list'],
          role: ['ROLE_ADMIN', 'ROLE_HEAD_COACH', 'ROLE_ASSISTANT_COACH']
        },
        {
          title: 'Oyuncular',
          link: ['/', 'tournaments', 'players'],
          role: ['ROLE_ADMIN', 'ROLE_HEAD_COACH', 'ROLE_ASSISTANT_COACH']
        },
        {
          title: 'Turnuva Başvuruları',
          link: ['/', 'tournaments', 'applications'],
          role: ['ROLE_ADMIN', 'ROLE_HEAD_COACH', 'ROLE_ASSISTANT_COACH']
        },
        {
          title: 'Maçlar',
          link: ['/', 'tournaments', 'matches'],
          role: ['ROLE_ADMIN', 'ROLE_HEAD_COACH', 'ROLE_ASSISTANT_COACH']
        }
      ]
    },
    {
      title: 'Maçlar',
      link: ['/', 'matches'],
      role: ['ROLE_ADMIN', 'ROLE_HEAD_COACH', 'ROLE_ASSISTANT_COACH']
    },
    {
      title: 'Kullanıcılar',
      link: ['/', 'users'],
      role: ['ROLE_ADMIN', 'ROLE_HEAD_COACH', 'ROLE_ASSISTANT_COACH'],
      child: []
    },
    {
      title: 'Haberler',
      link: ['/', 'news'],
      role: ['ROLE_ADMIN'],
      child: []
    },
    {
      title: 'Antrenman',
      role: ['ROLE_ADMIN'],
      child: [
        {
          title: 'Antrenmanlar',
          link: ['/', 'training', 'list'],
          role: ['ROLE_ADMIN']
        },
        {
          title: 'Kategoriler',
          link: ['/', 'training', 'category', 'list'],
          role: ['ROLE_ADMIN']
        }
      ]
    },
    {
      title: 'Sponsor & Partner',
      link: ['/', 'sponsor-partner'],
      role: ['ROLE_ADMIN'],
      child: []
    },
    {
      title: 'Mail',
      link: ['/', 'mail'],
      role: ['ROLE_ADMIN'],
      child: []
    },
    {
      title: 'Slider',
      link: ['/', 'slider'],
      role: ['ROLE_ADMIN'],
      child: []
    }
  ];

  constructor(private userService: UserService, private themeService: ThemeService) {
    this.theme = themeService.currentTheme;
  }

  ngOnInit(): void {
    this.activeUser = this.userService.firebaseUserInfo;
  }

  async toggleTheme(): Promise<void> {
    await this.themeService.toggleTheme();
  }

  async logOut(): Promise<void> {
    await this.userService.signOut();
  }
}
