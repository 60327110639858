import { Pipe, PipeTransform } from '@angular/core';
import { FirestoreService } from '@core/services/firestore.service';

@Pipe({
  name: 'league'
})
export class LeaguePipe implements PipeTransform {
  constructor(private firestoreService: FirestoreService) {}

  transform(value: string): string {
    const league = this.firestoreService.firestoreData.leagues.find((item) => item.id === value);
    return `${league?.lig_ad || ''} (${value})`;
  }
}
