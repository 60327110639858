import { Component, Input, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'app-match-summary-box',
  templateUrl: './match-summary-box.component.html'
})
export class MatchSummaryBoxComponent implements OnInit {
  @Input() className: string;

  @Input() match: Tournaments.Game;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  editMatch(): void {
    if (this.match.id) {
      this.router.navigate(['/tournaments/matches/edit/', this.match.id], {
        queryParams: { activeTab: 'turnuva' }
      });
    } else {
      const navigationExtras: NavigationExtras = {
        queryParams: {
          turnuva_id: this.match.turnuva_id,
          turnuva_sira_id: this.match.turnuva_sira_id,
          activeTab: 'turnuva'
        }
      };

      this.router.navigate(['/tournaments/matches/new'], navigationExtras);
    }
  }
}
