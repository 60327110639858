<form nz-form [formGroup]="loginForm" class="custom-form">
  <h1>Giriş Yap</h1>

  <nz-form-item class="custom-form__item">
    <nz-form-control class="custom-form__control custom-form__control--full"
                     nzErrorTip="Lütfen email giriniz!">
      <nz-input-group nzPrefixIcon="user">
        <input nzSize="large"
               formControlName="email"
               nz-input placeholder="Email" autocomplete="off" required />
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item class="custom-form__item">
    <nz-form-control class="custom-form__control custom-form__control--full"
                     nzErrorTip="Lütfen parola giriniz!">
      <nz-input-group nzPrefixIcon="key">
        <input nzSize="large"
               formControlName="password"
               type="password"
               nz-input placeholder="Parola" autocomplete="off" required />
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item class="custom-form__item">
    <button class="button"
            nz-button
            nzType="primary"
            type="submit"
            nzBlock
            (click)="submitForm()"
            [nzLoading]="loading">
      Giriş
    </button>
  </nz-form-item>

  <nz-form-item class="custom-form__item flex-center">
    veya
  </nz-form-item>

  <nz-form-item class="custom-form__item">
    <button class="button"
            nz-button
            type="submit"
            nzBlock
            (click)="googleLogin()"
            [nzLoading]="googleLoginLoading">
      <span nz-icon nzType="google" nzTheme="outline"></span> Google ile Giriş
    </button>
  </nz-form-item>
</form>
