<div class="fullscreen">
    <div class="fullscreen__image">
    </div>
    <div class="fullscreen__content">
        <div class="fullscreen__content__form">
          <img class="fullscreen__logo" src="assets/images/logo.png" alt="Logo Icon"/>
            <router-outlet></router-outlet>
        </div>
        <div class="fullscreen__content__help-text">
            Giriş yapmak için <a href="https://tenis.io/yardim" target="_blank">yardım alın</a>
        </div>
    </div>
</div>
