<nz-layout>
  <nz-sider class="sider">
    <a class="logo">
      <img src="assets/images/logo.png" alt="Logo Icon"/>
    </a>
    <ul nz-menu nzMode="inline">
      <ng-container *ngFor="let item of mainMenu">
        <ng-container *ngIf="!item.child?.length">
          <li nz-menu-item [routerLink]="item.link" nzMatchRouter *ngxPermissionsOnly="item.role">
            <span title>{{ item.title }}</span>
          </li>
        </ng-container>
        <ng-container *ngIf="item.child?.length">
          <li nz-submenu *ngxPermissionsOnly="item.role" [nzTitle]="item.title">
            <ul>
              <ng-container *ngFor="let child of item.child">
                <li nz-menu-item [routerLink]="child.link" nzMatchRouter
                    *ngxPermissionsOnly="child.role">
                  <span title>{{ child.title }}</span>
                </li>
              </ng-container>
            </ul>
          </li>
        </ng-container>
      </ng-container>
    </ul>
  </nz-sider>
  <nz-layout>
    <nz-header class="header">
      <a class="logo">
        <img src="assets/images/logo.png" alt="Logo Icon"/>
      </a>
      <div>
        <a nz-dropdown class="user-menu" [nzDropdownMenu]="menu" nzOverlayClassName="user-menu-overlay">
          {{ activeUser.ad }}
          <nz-avatar [nzSrc]="activeUser.foto_mini_url"
                     [nzSize]="32" nzIcon="user">
          </nz-avatar>
        </a>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu>
            <li nz-menu-item (click)="appearanceDrawer = true" *ngxPermissionsOnly="['ROLE_ADMIN']">
              <i nz-icon nzType="bg-colors"></i>Görünüm
            </li>
            <li nz-menu-item (click)="logOut()">
              <i nz-icon nzType="logout"></i>Çıkış Yap
            </li>
          </ul>
        </nz-dropdown-menu>
        <a nz-dropdown class="user-mobile-menu" [nzDropdownMenu]="mobileMenu" nzOverlayClassName="user-menu-overlay">
          <i nz-icon nzType="menu"></i>
        </a>
        <nz-dropdown-menu #mobileMenu="nzDropdownMenu">
          <ul nz-menu>
            <ng-container *ngFor="let item of mainMenu">
              <ng-container *ngIf="!item.child?.length">
                <li nz-menu-item [routerLink]="item.link" nzMatchRouter *ngxPermissionsOnly="item.role">
                  <span title>{{ item.title }}</span>
                </li>
              </ng-container>
              <ng-container *ngIf="item.child?.length">
                <li nz-submenu *ngxPermissionsOnly="item.role" [nzTitle]="item.title">
                  <ul>
                    <ng-container *ngFor="let child of item.child">
                      <li nz-menu-item [routerLink]="child.link" nzMatchRouter
                          *ngxPermissionsOnly="child.role">
                        <span title>{{ child.title }}</span>
                      </li>
                    </ng-container>
                  </ul>
                </li>
              </ng-container>
            </ng-container>
            <li nz-menu-divider></li>
            <li nz-menu-item (click)="appearanceDrawer = true" *ngxPermissionsOnly="['ROLE_ADMIN']">
              <i nz-icon nzType="bg-colors"></i>Görünüm
            </li>
            <li nz-menu-item (click)="logOut()">
              <i nz-icon nzType="logout"></i>Çıkış Yap
            </li>
          </ul>
        </nz-dropdown-menu>
      </div>
    </nz-header>
    <nz-content class="content">
      <router-outlet></router-outlet>
    </nz-content>
  </nz-layout>
</nz-layout>

<nz-drawer [nzVisible]="appearanceDrawer"
           nzPlacement="right"
           nzTitle="Görünüm"
           (nzOnClose)="appearanceDrawer = false">
  <ng-container *nzDrawerContent>
    <p>Tema</p>
    <nz-radio-group [(ngModel)]="theme" (ngModelChange)="toggleTheme()">
      <label nz-radio-button [nzValue]="themeType.default">Default</label>
      <label nz-radio-button [nzValue]="themeType.dark">Dark</label>
    </nz-radio-group>
  </ng-container>
</nz-drawer>
