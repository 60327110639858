<div class="container container--large">
  <nz-row [nzGutter]="[16, 16]">
    <nz-col nzXs="24" nzSm="6">
      <nz-card>
        <nz-statistic [nzValue]="(statistics.clubs | number)!" [nzTitle]="'KULÜP'"></nz-statistic>
      </nz-card>
    </nz-col>
    <nz-col nzXs="24" nzSm="6">
      <nz-card>
        <nz-statistic [nzValue]="(statistics.leagues | number)!" [nzTitle]="'LİG'"></nz-statistic>
      </nz-card>
    </nz-col>
    <nz-col nzXs="24" nzSm="6">
      <nz-card>
        <nz-statistic [nzValue]="(statistics.tournaments | number)!" [nzTitle]="'TURNUVA'"></nz-statistic>
      </nz-card>
    </nz-col>
    <nz-col nzXs="24" nzSm="6">
      <nz-card>
        <nz-statistic [nzValue]="(statistics.courts | number)!" [nzTitle]="'KORT'"></nz-statistic>
      </nz-card>
    </nz-col>
  </nz-row>
</div>
