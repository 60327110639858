import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// layout
import { LayoutDefaultComponent } from './layout/default/default.component';
import { FullscreenComponent } from './layout/fullscreen/fullscreen.component';

// pages
import { LoginComponent } from './login/login.component';
import { ResultComponent } from './result/result.component';
import { DashboardComponent } from './dashboard/dashboard.component';

// guards
import { AuthGuard } from '@core/security/auth.guard';
import { NgxPermissionsGuard } from 'ngx-permissions';

const routes: Routes = [
  {
    path: '',
    component: LayoutDefaultComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['ROLE_ADMIN'],
            redirectTo: '/clubs'
          }
        },
        component: DashboardComponent
      },
      {
        path: 'users',
        canActivate: [NgxPermissionsGuard],
        loadChildren: () => import('./users/user.module').then((m) => m.UserModule),
        data: {
          permissions: {
            only: ['ROLE_ADMIN', 'ROLE_HEAD_COACH', 'ROLE_ASSISTANT_COACH'],
            redirectTo: '/403'
          }
        }
      },
      {
        path: 'news',
        canActivate: [NgxPermissionsGuard],
        loadChildren: () => import('./news/news.module').then((m) => m.NewsModule),
        data: {
          permissions: {
            only: 'ROLE_ADMIN',
            redirectTo: '/403'
          }
        }
      },
      {
        path: 'training',
        canActivate: [NgxPermissionsGuard],
        loadChildren: () => import('./training/training.module').then((m) => m.TrainingModule),
        data: {
          permissions: {
            only: 'ROLE_ADMIN',
            redirectTo: '/403'
          }
        }
      },
      {
        path: 'sponsor-partner',
        canActivate: [NgxPermissionsGuard],
        loadChildren: () =>
          import('./sponsor-partner/sponsor-partner.module').then((m) => m.SponsorPartnerModule),
        data: {
          permissions: {
            only: 'ROLE_ADMIN',
            redirectTo: '/403'
          }
        }
      },
      {
        path: 'leagues',
        canActivate: [NgxPermissionsGuard],
        loadChildren: () => import('./league/league.module').then((m) => m.LeagueModule),
        data: {
          permissions: {
            only: ['ROLE_ADMIN', 'ROLE_HEAD_COACH', 'ROLE_ASSISTANT_COACH'],
            redirectTo: '/403'
          }
        }
      },
      {
        path: 'tournaments',
        canActivate: [NgxPermissionsGuard],
        loadChildren: () =>
          import('./tournament/tournament.module').then((m) => m.TournamentModule),
        data: {
          permissions: {
            only: ['ROLE_ADMIN', 'ROLE_HEAD_COACH', 'ROLE_ASSISTANT_COACH'],
            redirectTo: '/403'
          }
        }
      },
      {
        path: 'clubs',
        canActivate: [NgxPermissionsGuard],
        loadChildren: () => import('./club/club.module').then((m) => m.ClubModule),
        data: {
          permissions: {
            only: ['ROLE_ADMIN', 'ROLE_HEAD_COACH', 'ROLE_ASSISTANT_COACH'],
            redirectTo: '/403'
          }
        }
      },
      {
        path: 'courts',
        canActivate: [NgxPermissionsGuard],
        loadChildren: () => import('./court/court.module').then((m) => m.CourtModule),
        data: {
          permissions: {
            only: ['ROLE_ADMIN', 'ROLE_HEAD_COACH', 'ROLE_ASSISTANT_COACH'],
            redirectTo: '/403'
          }
        }
      },
      {
        path: 'mail',
        canActivate: [NgxPermissionsGuard],
        loadChildren: () => import('./mail/mail.module').then((m) => m.MailModule),
        data: {
          permissions: {
            only: 'ROLE_ADMIN',
            redirectTo: '/403'
          }
        }
      },
      {
        path: 'slider',
        canActivate: [NgxPermissionsGuard],
        loadChildren: () => import('./slider/slider.module').then((m) => m.SliderModule),
        data: {
          permissions: {
            only: 'ROLE_ADMIN',
            redirectTo: '/403'
          }
        }
      },
      {
        path: 'matches',
        canActivate: [NgxPermissionsGuard],
        loadChildren: () => import('./matches/matches.module').then((m) => m.MatchesModule),
        data: {
          permissions: {
            only: ['ROLE_ADMIN', 'ROLE_HEAD_COACH', 'ROLE_ASSISTANT_COACH'],
            redirectTo: '/403'
          }
        }
      },
      { path: '401', component: ResultComponent },
      { path: '403', component: ResultComponent },
      { path: '404', component: ResultComponent }
    ]
  },
  {
    path: '',
    component: FullscreenComponent,
    children: [
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      { path: 'login', component: LoginComponent }
    ]
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule]
})
export class RoutesRoutingModule {}
