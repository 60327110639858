import { Timestamp } from 'firebase/firestore';

export function documentToJSON(doc: any): any {
  if (!doc.exists) {
    return null;
  }

  const data = {
    ...doc.data(),
    id: doc.id
  };

  for (const [key, value] of Object.entries(data)) {
    const val = value as any;
    if (val?.seconds) {
      data[key] = new Date(data[key].seconds * 1000);
    }
  }

  return data;
}

export function JSONtoDocument(data: any): any {
  data.id = null;

  for (const [key, value] of Object.entries(data)) {
    const val = value as any;

    if (val instanceof Date) {
      data[key] = Timestamp.fromDate(val);
    }
  }

  return data;
}
