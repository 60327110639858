import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { NgxPermissionsService } from 'ngx-permissions';
import 'firebase/firestore';
import { map, take } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Observable } from 'rxjs';
import { documentToJSON, JSONtoDocument } from '@shared/utils/firebase.util';
import { FirestoreObjectEnum } from '@shared/enums/firestore-collection.enum';

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {
  firestoreData: {
    leagues: Leagues.League[];
    courts: Courts.Court[];
    tournaments: Tournaments.Tournament[];
    clubs: Clubs.Club[];
    coaches: Coaches.Coach[];
  } = {
    leagues: [],
    courts: [],
    tournaments: [],
    clubs: [],
    coaches: []
  };

  constructor(
    private permissionsService: NgxPermissionsService,
    private auth: AngularFireAuth,
    private afs: AngularFirestore
  ) {}

  getList(PATH: string): Observable<any[]> {
    return this.afs
      .collection<any>(PATH)
      .snapshotChanges()
      .pipe(
        map((actions: any[]) => {
          const data = actions.map((a) => documentToJSON(a.payload.doc));
          this.firestoreData[FirestoreObjectEnum[PATH]] = data;
          return data;
        })
      );
  }

  get(id: string, PATH: string): Observable<any> {
    return this.afs
      .doc<any>(`${PATH}/${id}`)
      .get()
      .pipe(
        take(1),
        map((actions) => documentToJSON(actions))
      );
  }

  create(data: any, PATH: string): Promise<any> {
    const id = data.id || this.afs.createId();

    return this.afs.doc<any>(`${PATH}/${id}`).set(JSONtoDocument(data));
  }

  update(data: any, PATH: string, id): Promise<any> {
    return this.afs.doc<any>(`${PATH}/${id}`).update(JSONtoDocument(data));
  }

  delete(id: string, PATH: string): Promise<any> {
    return this.afs.doc<any>(`${PATH}/${id}`).delete();
  }
}
