import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { UserService } from '@core/services/user.service';
import { Observable } from 'rxjs';
import { StorageTypesEnum } from '@shared/enums/storage-types.enum';
import { StorageService } from '@core/services/storage.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private userService: UserService, private storageService: StorageService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken = this.storageService.get(StorageTypesEnum.TOKEN);

    if (authToken) {
      req = req.clone({
        setHeaders: { Authorization: authToken }
      });
    }
    return next.handle(req);
  }
}
