import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';

import { UserService } from '@core/services/user.service';
import { ThemeService } from '@core/services/theme.service';

@Injectable({
  providedIn: 'root'
})
export class SetupService {
  authStateSubscribe;

  constructor(
    private auth: AngularFireAuth,
    private userService: UserService,
    private themeService: ThemeService
  ) {}

  async initialize(): Promise<void> {
    await this.themeService.loadTheme();

    return new Promise(async (resolve) => {
      this.authStateSubscribe = this.auth.authState.subscribe((user) => {
        if (user) {
          this.userService
            .setUserInfo(user?.uid)
            .then(() => {
              this.userService
                .getInitialData()
                .then(() => resolve())
                .catch(() => this.userService.signOut());
            })
            .catch(() => this.userService.signOut());
        } else {
          resolve();
          this.authStateSubscribe.unsubscribe();
        }
      });
    });
  }
}
