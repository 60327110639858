export enum FirestoreCollectionEnum {
  APPLICATION_LEAGUE_COLLECTION = 'basvuru_lig',
  NEWS_COLLECTION = 'haber',
  TRAINING_CATEGORY_COLLECTION = 'antrenman_kategori',
  TRAINING_COLLECTION = 'antrenman',
  STATISTIC_COLLECTION = 'istatistik',
  CLUB_COLLECTION = 'klup',
  COURT_COLLECTION = 'kort',
  COURT_AVAILABILITY_COLLECTION = 'kort_musaitlik',
  USERS_COLLECTION = 'kullanici',
  USER_AVAILABILITY_COLLECTION = 'kullanici_musaitlik',
  USER_PRIZE_COLLECTION = 'kullanici_odul',
  LEAGUES_COLLECTION = 'lig_temel',
  MATCH_STATUS_RESULT = 'mac_durum_sonuc',
  PRIZES_COLLECTION = 'odul',
  TOURNAMENT_COLLECTION = 'turnuva',
  TOURNAMENT_PARTICIPATE_COLLECTION = 'turnuva_katilim',
  TOURNAMENT_GAME_COLLECTION = 'turnuva_oyun',
  SPONSOR_PARTNER_COLLECTION = 'sponsor_partner',
  COACH_COLLECTION = 'antrenor',
  SLIDER_COLLECTION = 'slider'
}

export enum FirestoreObjectEnum {
  'klup' = 'clubs',
  'kort' = 'courts',
  'kullanici' = 'users',
  'lig_temel' = 'leagues',
  'turnuva' = 'tournaments',
  'antrenor' = 'coaches'
}
