import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { NgxPermissionsService } from 'ngx-permissions';
import 'firebase/firestore';
import { map, take } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { documentToJSON } from '@shared/utils/firebase.util';
import { StorageTypesEnum } from '@shared/enums/storage-types.enum';
import { StorageService } from '@core/services/storage.service';
import { FirestoreService } from '@core/services/firestore.service';
import { FirestoreCollectionEnum } from '@shared/enums/firestore-collection.enum';
import firebase from 'firebase/compat/app';
import {
  PRAMIT_ETKEN_PUAN_1,
  PRAMIT_ETKEN_PUAN_14,
  PRAMIT_ETKEN_PUAN_2,
  PRAMIT_ETKEN_PUAN_22,
  PRAMIT_ETKEN_PUAN_32,
  PRAMIT_ETKEN_PUAN_4,
  PRAMIT_ETKEN_PUAN_44,
  PRAMIT_ETKEN_PUAN_58,
  PRAMIT_ETKEN_PUAN_74,
  PRAMIT_ETKEN_PUAN_8
} from '@shared/variables/constant.variable';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  PATH = 'kullanici';

  firebaseUserInfo: Users.User;
  isAdmin: boolean;
  isHeadCoach: boolean;
  isAssistantCoach: boolean;
  isUser: boolean;
  relatedClubIds: string[] = [];
  relatedLeagueIds: string[] = [];

  users: Users.User[] = [];

  constructor(
    private permissionsService: NgxPermissionsService,
    private auth: AngularFireAuth,
    private afs: AngularFirestore,
    private storageService: StorageService,
    private router: Router,
    private firestoreService: FirestoreService
  ) {
    auth.onAuthStateChanged((user) => {
      if (user) {
        user.getIdToken().then((idToken) => {
          this.storageService.set(StorageTypesEnum.TOKEN, idToken);
        });
      } else {
        this.storageService.remove(StorageTypesEnum.TOKEN);
        this.clearPermissions();
      }
    });
  }

  isAuthority(): boolean {
    return this.isAdmin || this.isHeadCoach || this.isAssistantCoach;
  }

  checkAndSetRole(): boolean {
    this.clearPermissions();

    if (this.firebaseUserInfo?.yetki === 1) {
      this.isAdmin = true;
      this.permissionsService.loadPermissions(['ROLE_ADMIN']);
      return true;
    }

    const coachedClubIds = this.firestoreService.firestoreData.clubs
      .filter((item) => item.klup_koc_kullanici_id === this.firebaseUserInfo.id)
      .map((item) => item.id);

    if (coachedClubIds.length) {
      this.relatedClubIds = coachedClubIds;
      this.isHeadCoach = true;
      this.permissionsService.loadPermissions(['ROLE_HEAD_COACH']);
      this.setRelatedLeagueIds();
      return true;
    }

    const asistanClubIds = this.firestoreService.firestoreData.coaches
      .filter((item) => item.kullanici_id === this.firebaseUserInfo.id)
      .map((item) => item.klup_id);

    if (asistanClubIds.length) {
      this.relatedClubIds = asistanClubIds;
      this.isAssistantCoach = true;
      this.permissionsService.loadPermissions(['ROLE_ASSISTANT_COACH']);
      this.setRelatedLeagueIds();
      return true;
    }

    return false;
  }

  setRelatedLeagueIds(): void {
    this.relatedLeagueIds = this.firestoreService.firestoreData.leagues
      .filter((item) => this.relatedClubIds.indexOf(item.klup_id) > -1)
      .map((item) => item.id);
  }

  setUserInfo(uid): Promise<void> {
    return new Promise((resolve, reject) => {
      this.afs
        .collection(this.PATH, (ref) => ref.where('uid', '==', uid))
        .snapshotChanges()
        .subscribe((response: any) => {
          const res = response.map((a) => documentToJSON(a.payload.doc));

          if (res.length < 0) {
            reject({
              message: 'Bu paneli kullanmanız için yetkiniz yok!'
            });
            return;
          }

          this.firebaseUserInfo = res[0];

          this.getInitialData().then(() => {
            if (this.checkAndSetRole()) {
              resolve();
            } else {
              reject({
                message: 'Bu paneli kullanmanız için yetkiniz yok!'
              });
            }
          });
        });
    });
  }

  async goTo(route: string): Promise<void> {
    await this.router.navigateByUrl(route);
  }

  clearPermissions(): void {
    this.permissionsService.flushPermissions();
    this.isAdmin = false;
    this.isUser = false;
    this.isAssistantCoach = false;
    this.isHeadCoach = false;
    this.relatedClubIds = [];
    this.relatedLeagueIds = [];
  }

  getUsers(): Observable<Users.User[]> {
    return this.afs
      .collection<any>(this.PATH)
      .snapshotChanges()
      .pipe(
        map((actions: any[]) => {
          const users = actions
            .map((a) => documentToJSON(a.payload.doc))
            .sort((a, b) => a?.ad?.localeCompare(b?.ad));
          this.users = users;
          return users;
        })
      );
  }

  getInitialData(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      combineLatest(
        this.getUsers(),
        this.firestoreService.getList(FirestoreCollectionEnum.LEAGUES_COLLECTION),
        this.firestoreService.getList(FirestoreCollectionEnum.TOURNAMENT_COLLECTION),
        this.firestoreService.getList(FirestoreCollectionEnum.CLUB_COLLECTION),
        this.firestoreService.getList(FirestoreCollectionEnum.COACH_COLLECTION),
        this.firestoreService.getList(FirestoreCollectionEnum.COURT_COLLECTION)
      ).subscribe(
        () => resolve(true),
        (error) => {
          reject();
          console.log('error', error);
        }
      );
    });
  }

  signIn(credential): any {
    const { email, password } = credential;

    return this.auth.signInWithEmailAndPassword(email, password);
  }

  googleSignIn(): any {
    const provider = new firebase.auth.GoogleAuthProvider();
    return this.auth.signInWithPopup(provider);
  }

  async signOut(): Promise<void> {
    await this.auth.signOut();
    this.clearPermissions();
    await this.router.navigateByUrl('/login');
  }

  deleteUser(id: string): Promise<void> {
    return this.afs.doc<any>(`${this.PATH}/${id}`).delete();
  }

  getUserListWithQuery(ligTemelId: string): Observable<any[]> {
    return this.afs
      .collection<any>(FirestoreCollectionEnum.USERS_COLLECTION, (ref) =>
        ref
          .where('lig_temel_id', '==', ligTemelId)
          .orderBy('puan', 'desc')
          .orderBy('galibiyet', 'desc')
          .orderBy('maglubiyet', 'asc')
          .orderBy('ad', 'asc')
          .orderBy('soyad', 'asc')
      )
      .snapshotChanges()
      .pipe(
        take(1),
        map((actions: any[]) => actions.map((a) => documentToJSON(a.payload.doc)))
      );
  }

  getPointByOrderNo(order: number): number {
    if (order < 2) {
      return PRAMIT_ETKEN_PUAN_1;
    } else if (order < 4) {
      return PRAMIT_ETKEN_PUAN_2;
    } else if (order < 8) {
      return PRAMIT_ETKEN_PUAN_4;
    } else if (order < 14) {
      return PRAMIT_ETKEN_PUAN_8;
    } else if (order < 22) {
      return PRAMIT_ETKEN_PUAN_14;
    } else if (order < 32) {
      return PRAMIT_ETKEN_PUAN_22;
    } else if (order < 44) {
      return PRAMIT_ETKEN_PUAN_32;
    } else if (order < 58) {
      return PRAMIT_ETKEN_PUAN_44;
    } else if (order < 74) {
      return PRAMIT_ETKEN_PUAN_58;
    } else {
      return PRAMIT_ETKEN_PUAN_74;
    }
  }

  async pyramidUpdate(users: Users.User[]): Promise<Users.User[]> {
    let orderNo = 1;
    const newUserList: Users.User[] = [];
    for (const user of users) {
      const updateObj: Partial<Users.User> = {
        piramit_puan: user.puan,
        piramit_sira_gecmis: [...user.piramit_sira_gecmis, user.piramit_sira],
        piramit_sira: orderNo,
        piramit_etken_puan: this.getPointByOrderNo(orderNo)
      };
      await this.afs
        .doc<any>(`${FirestoreCollectionEnum.USERS_COLLECTION}/${user.id}`)
        .update(updateObj);
      newUserList.push({
        ...user,
        ...updateObj
      });
      orderNo++;
    }
    return newUserList;
  }

  async removeFromLeague(userId: string): Promise<void> {
    const updateObj: Partial<Users.User> = {
      lig_temel_id: null,
      puan: 0,
      galibiyet: 0,
      maglubiyet: 0,
      piramit_sira: 0,
      piramit_puan: 0,
      piramit_etken_puan: 0,
      piramit_sira_gecmis: [],
      verified_account: false
    };
    return await this.afs
      .doc<any>(`${FirestoreCollectionEnum.USERS_COLLECTION}/${userId}`)
      .update(updateObj)
      .then(() => {
        this.users = this.users.map((item) => {
          if (item.id === userId) {
            item = {
              ...item,
              ...updateObj
            };
          }
          return item;
        });
      });
  }
}
