<div class="match-summary-box {{className}}">
  <button nz-button nzShape="circle"
          (click)="editMatch()">
    <i nz-icon nzType="edit"></i>
  </button>
  <div class="match-summary-box-info">
    <div>
      {{match.mac_saati}}
    </div>
    <div>{{match.kort_ad}}</div>
  </div>
  <div class="match-summary-box-row">
    <div class="match-summary-box-player"></div>
    <div class="match-summary-box-scores">
      <div class="match-summary-box-score match-summary-box-score--title">Maç</div>
      <div class="match-summary-box-score match-summary-box-score--title">1</div>
      <div class="match-summary-box-score match-summary-box-score--title">2</div>
      <div class="match-summary-box-score match-summary-box-score--title">3</div>
    </div>
  </div>
  <div class="match-summary-box-row">
    <div class="match-summary-box-player">
      {{match.kullanici_t_adsoyad}} <br>
      {{match.kullanici_t_partner_adsoyad}}
    </div>
    <div class="match-summary-box-scores">
      <div class="match-summary-box-score">{{match.kul_t_sonuc}}</div>
      <div class="match-summary-box-score">
        {{match.kul_t_set1}}
      </div>
      <div class="match-summary-box-score">
        {{match.kul_t_set2}}
      </div>
      <div class="match-summary-box-score">
        {{match.kul_t_set3}}
      </div>
    </div>
  </div>
  <hr />
  <div class="match-summary-box-row">
    <div class="match-summary-box-player">
      {{match.kullanici_r_adsoyad}} <br>
      {{match.kullanici_r_partner_adsoyad}}
    </div>
    <div class="match-summary-box-scores">
      <div class="match-summary-box-score">{{match.kul_r_sonuc}}</div>
      <div class="match-summary-box-score">
        {{match.kul_r_set1}}
      </div>
      <div class="match-summary-box-score">
        {{match.kul_r_set2}}
      </div>
      <div class="match-summary-box-score">
        {{match.kul_r_set3}}
      </div>
    </div>
  </div>
</div>
