import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '@core/services/firestore.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit {
  statistics;

  constructor(private firestoreService: FirestoreService) {}

  ngOnInit(): void {
    for (const [key, value] of Object.entries(this.firestoreService.firestoreData)) {
      this.statistics = {
        [key]: value.length,
        ...this.statistics
      };
    }
  }
}
