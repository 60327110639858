<ng-container [ngSwitch]="path">
  <ng-container *ngSwitchCase="401">
    <nz-result nzStatus="403" nz-tooltip nzTooltipTitle="401" nzSubTitle="Giriş yapmanız gerekiyor.">
      <div nz-result-extra>
        <a routerLink="/login">
          <button nz-button nzType="primary">Giriş Yap</button>
        </a>
      </div>
    </nz-result>
  </ng-container>
  <ng-container *ngSwitchCase="403">
    <nz-result nzStatus="403" nz-tooltip nzTooltipTitle="403" nzSubTitle="Bu sayfa için yetkiniz yok.">
      <div nz-result-extra>
        <a routerLink="/"><button nz-button nzType="primary">Ana sayfaya Dön</button></a>
      </div>
    </nz-result>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <nz-result nzStatus="404" nz-tooltip nzTooltipTitle="404" nzSubTitle="Sayfa görüntülenemiyor.">
      <div nz-result-extra>
        <a routerLink="/"><button nz-button nzType="primary">Ana sayfaya Dön</button></a>
      </div>
    </nz-result>
  </ng-container>
</ng-container>
