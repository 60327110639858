import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { validateFormGroup } from '@shared/utils/form.util';

import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Router } from '@angular/router';
import { UserService } from '@core/services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  loading = false;
  googleLoginLoading = false;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private router: Router,
    private notificationService: NzNotificationService
  ) {}

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      email: [null, [Validators.required]],
      password: [null, [Validators.required]],
      remember: [false]
    });
  }

  submitForm(): void {
    if (this.loading || this.googleLoginLoading) {
      return;
    }

    validateFormGroup(this.loginForm);

    if (this.loginForm.invalid) {
      return;
    }

    const data = {
      email: this.loginForm.value.email,
      password: this.loginForm.value.password
    };

    this.loading = true;

    this.userService
      .signIn(data)
      .then((response) => {
        this.userService
          .setUserInfo(response.user.uid)
          .then(() => {
            this.router.navigateByUrl('/dashboard');
            this.loading = false;
          })
          .catch((reason) => {
            this.loading = false;
            this.notificationService.error('Hata!', reason.message);
          });
      })
      .catch((reason) => {
        this.loading = false;
        this.notificationService.error('Hata!', reason.message);
      });
  }

  ngOnDestroy(): void {}

  googleLogin(): void {
    if (this.googleLoginLoading || this.loading) {
      return;
    }
    this.googleLoginLoading = true;
    this.userService
      .googleSignIn()
      .then((response) => {
        this.userService
          .setUserInfo(response.user.uid)
          .then(() => {
            this.router.navigateByUrl('/dashboard');
            this.googleLoginLoading = false;
          })
          .catch((reason) => {
            this.googleLoginLoading = false;
            this.notificationService.error('Hata!', reason.message);
          });
      })
      .catch((reason) => {
        this.googleLoginLoading = false;
        this.notificationService.error('Hata!', reason.message);
      });
  }
}
